<template lang="html">
  <div>
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card class="elevation-1">
        <v-card-title>
          <h2 class="headline mb-0">{{ $tc('title.novo_ajuste', 1) }}</h2>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-col cols="12">
            <v-radio-group
            :disabled="somenteLeitura || !this.novo && status !== 'EM_ANALISE'"
              v-model="novoAjuste.tipoAjuste"
              :rules="[rules.required]"
              @change="habilitaTipoVerbaEValor()"
              row>
              <v-radio
                :label="`${$tc('label.adendo')}`"
                :value="'ADENDO'"
              ></v-radio>
              <v-radio
                :label="`${$tc('label.reducao')}`"
                :value="'REDUCAO'"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="tipo_verba"
                name="tipo_verba"
                :items="tipoVerbaLista"
                item-text="nome"
                item-value="id"
                clearable
                :disabled="somenteLeitura || disableTipoVerbaEValor"
                v-model="novoAjuste.idTipoVerbaDestino"
                @input="changeTipoVerba"
                :label="`${$tc('label.tipo_verba', 1)} *`"
                :rules="[rules.required]">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="orcamento"
                name="orcamento"
                :items="orcamentoLista"
                item-text="descricao"
                item-value="id"
                clearable
                v-model="novoAjuste.idOrcamentoDestino"
                @input="changeOrcamento"
                :disabled="somenteLeitura || disableOrcamento"
                :label="`${$tc('label.orcamento', 1)} *`"
                :rules="[rules.required]">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="periodo_orcamentario"
                name="periodo_orcamentario"
                :items="periodoOrcamentarioLista"
                item-text="descricao"
                item-value="id"
                clearable
                v-model="novoAjuste.idPeriodoOrcamentarioDestino"
                @input="changePeriodoOrcamentario"
                :disabled="somenteLeitura || disablePeriodoOrcamentario"
                :label="`${$tc('label.periodo_orcamentario', 1)} *`"
                :rules="[rules.required]">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="tipo_linha_orcamento"
                name="tipo_linha_orcamento"
                :items="linhaInvestimentoLista"
                item-text="descricao"
                item-value="id"
                clearable
                v-model="novoAjuste.idLinhaInvestimentoDestino"
                @input="changeLinhaInvestimento"
                :disabled="somenteLeitura || disableLinhaInvestimento"
                :label="`${$tc('label.linha_de_investimento', 1)} *`"
                :rules="[rules.required]">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="configuracaoCarregada && configuracao.rateioDivisao">
              <v-autocomplete
                id="divisao_conta"
                name="divisao_conta"
                :items="divisoesConta"
                item-text="nome"
                item-value="id"
                clearable
                v-model="novoAjuste.idControladorTradeDestino"
                @input="changeDivisoesConta"
                :disabled="somenteLeitura || disableDivisoesConta"
                :label="`${$tc('label.divisao', 1)} *`"
                :rules="[rules.required]">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                v-if="configuracaoCarregada && configuracao.ehPercentual"
                :label="$t('label.valor_planejado')"
                disabled
                v-model="valorPlanejado"
                suffix="%"
                type="Number"
                min="0">
              </input-decimal>
              <input-money
                v-else
                :label="$t('label.valor_planejado')"
                disabled
                v-model="valorPlanejado">
              </input-money>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                v-if="configuracaoCarregada && configuracao.ehPercentual"
                :label="$t('label.saldo_disponivel')"
                disabled
                v-model="saldoDisponivel"
                suffix="%"
                type="Number"
                min="0">
              </input-decimal>
              <input-money
                v-else
                :label="$t('label.saldo_disponivel')"
                disabled
                v-model="saldoDisponivel">
              </input-money>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                v-if="configuracaoCarregada && configuracao.ehPercentual"
                :label="`${$t('label.valor_ajuste')} *`"
                :rules="[rules.required, rules.valorAjusteNegativo]"
                @input="validaValor"
                v-model="novoAjuste.valor"
                suffix="%"
                :disabled="somenteLeitura || disableTipoVerbaEValor"
                type="Number"
                min="0">
              </input-decimal>
              <input-money
                v-else
                :disabled="somenteLeitura || disableTipoVerbaEValor"
                :label="`${$t('label.valor_ajuste')} *`"
                :rules="[rules.required, rules.valorAjusteNegativo]"
                @input="validaValor"
                v-model="novoAjuste.valor">
              </input-money>
            </v-col>
            <v-col cols="12" sm="6" md="4" slot="antes">
              <v-text-field
                id="status"
                name="status"
                v-model="status"
                disabled
                :label="$tc('label.status', 1)">
              </v-text-field>
            </v-col>
          </v-row>
          <v-alert type="error"
                   v-if="mostrarAlertaSaldoNegativo"
                   elevation="2"
          >
            {{ this.$t('errors.valor_maior_saldo') }}
          </v-alert>
          <v-row>
            <v-col>
              <v-textarea
                v-if="isStatusAprovado"
                id="justificativa"
                name="justificativa"
                :disabled="somenteLeitura"
                v-model="novoAjuste.justificativa"
                :label="`${$tc('label.justificativa', 1)} *`"
                :rules="[rules.required]"
                rows="1"
                auto-grow
                :counter="500"
                maxlength="500"
              >
              </v-textarea>
              <v-text-field
                  v-else
                  id="justificativa"
                  name="justificativa"
                  :disabled="somenteLeitura"
                  v-model="novoAjuste.justificativa"
                  :label="`${$tc('label.justificativa', 1)} *`"
                  :rules="[rules.required]"
                  :counter="150"
                  maxlength="150"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btn_voltar"
            v-if="somenteLeitura"
            @click.native="voltar">
            {{ $t('label.voltar') }}
          </v-btn>
          <v-btn id="voltar"
            v-if="!somenteLeitura"
            @click="abrirModalConfirmacao('voltar')">
            {{ $t('label.voltar') }}
          </v-btn>
          <v-btn id="concluir" @click="abrirModalConfirmacao('concluir')"
            color="red"
            dark
            v-if="exibeConcluir">
            {{ $t('label.concluir') }}
          </v-btn>
          <v-btn @click="abrirModalConfirmacao('solicitarAprovacao')"
            color="red"
            dark
            v-if="exibeSolicitarAprovacao">
            {{ $t('label.solicitar_aprovacao') }}
          </v-btn>
          <v-btn @click="abrirJustificativaAprovar()"
            dark
            color="primary"
            v-show="exibeBtnAvaliacao">
            {{ $t('label.aprovar') }}
          </v-btn>
          <v-btn @click="abrirJustificativaReprovar()"
            dark
            color="red"
            v-show="exibeBtnAvaliacao">
            {{ $t('label.reprovar') }}
          </v-btn>
          <v-btn @click="abrirJustificativaAnalise()"
            dark
            color="primary"
            v-show="exibeBtnAvaliacao">
            {{ $tc('label.enviar_analise', 1) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="executarAcao"
    />
    <workflow-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    />
  </div>
</template>
<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import AjusteVerbaFormFluxo from '../AjusteVerbaFormFluxo';
import WorkflowJustificativa from '../../../shared-components/workflow/WorkflowJustificativa';
import OrcamentoConfiguracao from '../../orcamento/OrcamentoConfiguracao';
import InputDecimal from '../../../shared-components/inputs/InputDecimal';

export default {
  name: 'AjusteVerbaAdendoReducao',
  data() {
    return {
      ajusteVerbaResource: this.$api.ajusteVerba(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),
      configuracao: null,
      configuracaoCarregada: false,

      mensagemConfirmacao: null,
      acaoConfirmacao: null,

      novoAjuste: {
        id: null,
        tipoAjuste: null,
        idTipoVerbaDestino: null,
        idOrcamentoDestino: null,
        idPeriodoOrcamentarioDestino: null,
        idLinhaInvestimentoDestino: null,
        idControladorTradeDestino: null,
        status: 'EM_CADASTRO',
        valor: 0,
        justificativa: null,
      },
      status: 'Novo',
      mostrarAlertaSaldoNegativo: false,
      tipoVerbaLista: [],
      orcamentoLista: [],
      linhaInvestimentoLista: [],
      periodoOrcamentarioLista: [],
      divisoesConta: [],
      disableTipoVerbaEValor: true,
      disableOrcamento: true,
      disablePeriodoOrcamentario: true,
      disableLinhaInvestimento: true,
      disableDivisoesConta: true,
      valorPlanejado: 0,
      saldoDisponivel: 0,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorAjusteNegativo: (value) => value > 0 || this.$t('errors.valor_negativo'),
      },
    };
  },
  mixins: [
    AjusteVerbaFormFluxo,
  ],
  props: {
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
    novo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputDecimal,
    InputMoney,
    Confirm,
    WorkflowJustificativa,
  },
  computed: {
    ...generateComputed('AJUSTE_VERBA', [
      'canAccessPage',
    ]),
    exibeSolicitarAprovacao() {
      return this.configuracaoCarregada
             && this.possuiFluxoHabilitado
             && this.novoAjuste.status === 'EM_CADASTRO'
             && this.novoAjuste.solicitanteFluxo;
    },
    exibeConcluir() {
      return this.configuracaoCarregada
             && this.novoAjuste.status === 'EM_CADASTRO'
             && !this.possuiFluxoHabilitado;
    },
    possuiFluxoHabilitado() {
      return this.configuracao
             && this.configuracao.fluxoHabilitado;
    },
    isValorInvalido() {
      return this.novoAjuste
          && this.novoAjuste.tipoAjuste === 'REDUCAO'
          && this.novoAjuste.valor > this.saldoDisponivel
          && !this.novoAjuste.status === 'APROVADO';
    },
    isStatusAprovado() {
      return this.novoAjuste.status === 'APROVADO';
    },
  },
  methods: {
    buscarTiposVerba() {
      const param = {
        tipoAjuste: this.novoAjuste.tipoAjuste,
      };
      this.ajusteVerbaResource.listarTipoVerba(param)
        .then((res) => {
          this.tipoVerbaLista = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarOrcamentos(autocomplete = null) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;
      if (idTipoVerba) {
        this.ajusteVerbaResource.listarOrcamento({ idTipoVerba, autocomplete })
          .then((res) => {
            this.orcamentoLista = res.data;
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    buscarLinhaInvestimentos(autocomplete = null) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;
      const idOrcamento = this.novoAjuste.idOrcamentoDestino;
      const idPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioDestino;
      if (idPeriodoOrcamentario) {
        this.ajusteVerbaResource.listarLinhaInvestimento({
          idTipoVerba,
          idOrcamento,
          idPeriodoOrcamentario,
          autocomplete,
        }).then((res) => {
          this.linhaInvestimentoLista = res.data;
        }, (err) => {
          this.$error(this, err);
        });
      }
    },
    buscarDivisoesConta(autocomplete = null) {
      const idLinhaInvestimento = this.novoAjuste.idLinhaInvestimentoDestino;
      const idPeriodoOrcamentario = this.novoAjuste.idPeriodoOrcamentarioDestino;
      this.ajusteVerbaResource.listarContaDivisao({
        idLinhaInvestimento,
        idPeriodoOrcamentario,
        autocomplete,
      }).then((res) => {
        this.divisoesConta = res.data;
      }, (err) => {
        this.$error(this, err);
      });
    },
    buscarPeriodoOrcamentarios(autocomplete = null) {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;
      const idOrcamento = this.novoAjuste.idOrcamentoDestino;
      if (idOrcamento) {
        this.ajusteVerbaResource.listarPeriodoOrcamentario({
          idTipoVerba,
          idOrcamento,
          autocomplete,
        }).then((res) => {
          this.periodoOrcamentarioLista = res.data;
        }, (err) => {
          this.$error(this, err);
        });
      }
    },
    changeTipoVerba() {
      this.disableOrcamento = !this.novoAjuste.idTipoVerbaDestino;
      this.novoAjuste.idOrcamentoDestino = null;
      this.novoAjuste.idPeriodoOrcamentarioDestino = null;
      this.novoAjuste.idLinhaInvestimentoDestino = null;
      this.novoAjuste.idControladorTradeDestino = null;
      this.valorPlanejado = 0;
      this.saldoDisponivel = 0;
      this.novoAjuste.valor = 0;
      this.disableLinhaInvestimento = true;
      this.disableDivisoesConta = true;
      this.disablePeriodoOrcamentario = true;
      this.buscarOrcamentos();
      this.configuracaoCarregada = false;
    },
    changeOrcamento() {
      this.disablePeriodoOrcamentario = !this.novoAjuste.idOrcamentoDestino;
      this.novoAjuste.idPeriodoOrcamentarioDestino = null;
      this.novoAjuste.idLinhaInvestimentoDestino = null;
      this.novoAjuste.idControladorTradeDestino = null;
      this.valorPlanejado = 0;
      this.saldoDisponivel = 0;
      this.novoAjuste.valor = 0;
      this.disableLinhaInvestimento = true;
      this.disableDivisoesConta = true;
      this.buscarPeriodoOrcamentarios();
      if (this.novoAjuste.idOrcamentoDestino && this.novoAjuste.idTipoVerbaDestino) {
        this.verificarUsuarioSolicitante();
        this.carregarConfiguracao();
      } else {
        this.configuracaoCarregada = false;
      }
    },
    changePeriodoOrcamentario() {
      this.disableLinhaInvestimento = !this.novoAjuste.idOrcamentoDestino;
      this.novoAjuste.idLinhaInvestimentoDestino = null;
      this.novoAjuste.idControladorTradeDestino = null;
      this.valorPlanejado = 0;
      this.saldoDisponivel = 0;
      this.novoAjuste.valor = 0;
      this.disableDivisoesConta = true;
      this.buscarLinhaInvestimentos();
    },
    changeLinhaInvestimento() {
      const idLinhaInvestimento = this.novoAjuste.idLinhaInvestimentoDestino;
      this.disableDivisoesConta = !idLinhaInvestimento;
      this.novoAjuste.idControladorTradeDestino = null;
      if (!idLinhaInvestimento) {
        this.valorPlanejado = 0;
        this.saldoDisponivel = 0;
        this.novoAjuste.valor = 0;
      } else {
        this.buscarDivisoesConta();
        this.ajusteVerbaResource.getPlanejadoSaldo({
          idLinhaInvestimento: this.novoAjuste.idLinhaInvestimentoDestino,
          idPeriodoOrcamentario: this.novoAjuste.idPeriodoOrcamentarioDestino,
        })
          .then((res) => {
            this.valorPlanejado = res.data.planejado;
            this.saldoDisponivel = res.data.saldo;
            this.validaValor();
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    changeDivisoesConta() {
      const idControlador = this.novoAjuste.idControladorTradeDestino;
      const param = {
        idLinhaInvestimento: this.novoAjuste.idLinhaInvestimentoDestino,
        idPeriodoOrcamentario: this.novoAjuste.idPeriodoOrcamentarioDestino,
      };
      if (idControlador) {
        param.idControlador = idControlador;
      }
      this.ajusteVerbaResource.getPlanejadoSaldo(param)
        .then((res) => {
          this.valorPlanejado = res.data.planejado;
          this.saldoDisponivel = res.data.saldo;
          this.validaValor();
        }, (err) => {
          this.$error(this, err);
        });
    },
    habilitaTipoVerbaEValor() {
      this.disableTipoVerbaEValor = false;
      this.novoAjuste.idTipoVerbaDestino = null;
      this.buscarTiposVerba();
      this.changeTipoVerba();
    },
    validaValor() {
      this.mostrarAlertaSaldoNegativo = this.isValorInvalido;
    },
    voltar() {
      this.$router.push({
        name: this.$route.params.from || 'ajuste-verba',
      });
    },
    concluir() {
      if (!this.$refs.form.validate() || this.isValorInvalido) return;
      this.salvarEConcluir();
    },
    carregarConfiguracao() {
      const idTipoVerba = this.novoAjuste.idTipoVerbaDestino;

      const promise = this.configuracaoResource.buscarConfigVigente({ idTipoVerba });

      promise.then((res) => {
        this.configuracao = new OrcamentoConfiguracao(res.data);
        this.configuracaoCarregada = true;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirModalConfirmacao(acao) {
      if (acao === 'voltar') {
        this.mensagemConfirmacao = this.$t('message.tem_certeza_cancelar');
        this.acaoConfirmacao = 'voltar';
      } else if (acao === 'concluir') {
        this.mensagemConfirmacao = this.$t('message.deseja_concluir', { text: this.$tc('label.ajuste_verba', 1).toLowerCase() });
        this.acaoConfirmacao = 'concluir';
      } else if (acao === 'solicitarAprovacao') {
        this.mensagemConfirmacao = this.$t('message.deseja_solicitar_aprovacao_ajuste_verba');
        this.acaoConfirmacao = 'solicitarAprovacao';
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    executarAcao() {
      if (this.acaoConfirmacao === 'voltar') {
        this.voltar();
      } else if (this.acaoConfirmacao === 'concluir') {
        this.concluir();
      } else if (this.acaoConfirmacao === 'solicitarAprovacao') {
        this.salvarESolicitarAprovacao();
      }
    },
    carregarDados(idAjusteVerba) {
      this.ajusteVerbaResource.pegarAjusteVerbaPorId({ idAjusteVerba })
        .then((res) => {
          const status = res.data.status.toLowerCase();
          this.status = this.$tc(`status_entidade.${status}`, 1);
          this.novoAjuste.id = idAjusteVerba;
          this.novoAjuste.valor = res.data.valor;
          this.novoAjuste.status = res.data.status;
          this.novoAjuste.tipoAjuste = res.data.tipo_ajuste;
          this.novoAjuste.justificativa = res.data.justificativa;
          this.novoAjuste.idTipoVerbaDestino = res.data.id_tipo_verba_destino;
          this.novoAjuste.idOrcamentoDestino = res.data.id_orcamento_destino;
          this.novoAjuste.idLinhaInvestimentoDestino = res.data.id_linha_investimento_destino;
          this.novoAjuste.idPeriodoOrcamentarioDestino = res.data.id_periodo_orcamentario_destino;
          this.novoAjuste.idControladorTradeDestino = res.data.id_controlador_trade_destino;
          this.valorPlanejado = res.data.valor_planejado;
          this.saldoDisponivel = res.data.saldo_disponivel;
          this.disableTipoVerbaEValor = false;
          this.disableOrcamento = false;
          this.disableLinhaInvestimento = false;
          this.disablePeriodoOrcamentario = false;
          this.buscarTiposVerba();
          this.buscarOrcamentos();
          this.verificarUsuarioSolicitante();
          this.verificarUsuarioAprovador();
          this.carregarConfiguracao();
          this.buscarPeriodoOrcamentarios();
          this.buscarLinhaInvestimentos();
          this.validaValor();
          if (this.novoAjuste.idControladorTradeDestino) {
            this.disableDivisoesConta = false;
            this.buscarDivisoesConta();
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    carregarSeUsuarioPossuirAcesso(idAjuste) {
      this.ajusteVerbaResource.verificarSeUsuarioPossuiAcesso({ idAjuste })
        .then((res) => {
          if (res.data) {
            this.carregarDados(idAjuste);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
      return;
    }

    if (!this.novo) {
      const { id, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(id);
      } else {
        this.carregarDados(id);
      }
    }
  },
};
</script>
